import {eventEmitter, application, CatalogCurrency, esputnikTracker} from 'devSrc/common/index';
import {AddToCartWindow} from "devSrc/products/addToCartWindow";
import axios from "axios";
import {authRegister} from '../../common';

class Product {
    /** @param {Object} params **/
    constructor(params) {
        this.productType = 0;
        this.currencyList = null;
        this.showQuantity = true;
        this.showAbsent = true;
        this.secondPict = false;
        this.showOldPrice = false;
        this.showMaxQuantity = 'N';
        this.relativeQuantityFactor = 5;
        this.showPercent = false;
        this.showSkuProps = false;
        this.basketAction = 'ADD';
        this.showClosePopup = false;
        this.useCompare = false;
        this.showSubscription = false;
        this.ajaxPageUrl = null;
        this.visual = {
            ID: '',
            PICT_ID: '',
            SECOND_PICT_ID: '',
            PICT_SLIDER_ID: '',
            QUANTITY_ID: '',
            QUANTITY_UP_ID: '',
            QUANTITY_DOWN_ID: '',
            PRICE_ID: '',
            PRICE_OLD_ID: '',
            DSC_PERC: '',
            SECOND_DSC_PERC: '',
            DISPLAY_PROP_DIV: '',
            BASKET_PROP_DIV: '',
            SUBSCRIBE_ID: ''
        };
        this.product = {
            checkQuantity: false,
            maxQuantity: 0,
            stepQuantity: 1,
            isDblQuantity: false,
            canBuy: true,
            name: '',
            pict: {},
            id: 0,
            addUrl: '',
            buyUrl: ''
        };
        this.originalProductFields = (params.PRODUCT && typeof params.PRODUCT === 'object') ? params.PRODUCT : {};
        this.basketMode = '';
        this.basketData = {
            useProps: false,
            emptyProps: false,
            quantity: 'quantity',
            props: 'prop',
            basketUrl: '',
            sku_props: '',
            sku_props_var: 'basket_props',
            add_url: '',
            buy_url: ''
        };

        this.compareData = {
            compareUrl: '',
            compareDeleteUrl: '',
            comparePath: ''
        };

        this.defaultPict = null;

        this.defaultSliderOptions = {
            interval: 3000,
            wrap: true
        };
        this.slider = {
            options: {},
            items: [],
            active: null,
            sliding: null,
            paused: null,
            interval: null,
            progress: null
        };
        this.touch = null;

        this.quantityDelay = null;
        this.quantityTimer = null;

        this.checkQuantity = false;
        this.maxQuantity = 0;
        this.minQuantity = 0;
        this.stepQuantity = 1;
        this.isDblQuantity = false;
        this.canBuy = true;
        this.precision = 6;
        this.precisionFactor = Math.pow(10, this.precision);
        this.bigData = false;
        this.fullDisplayMode = false;
        this.displayTimer = false;
        this.viewMode = '';
        this.templateTheme = '';

        this.currentPriceMode = '';
        this.currentPrices = [];
        this.currentPriceSelected = 0;
        this.currentQuantityRanges = [];
        this.currentQuantityRangeSelected = 0;

        this.offers = [];
        this.offerNum = 0;
        this.treeProps = [];
        this.selectedValues = {};

        this.obProduct = null;
        this.productParams = {};
        this.blockNodes = {};
        this.obQuantity = null;
        this.obQuantityUp = null;
        this.obQuantityDown = null;
        this.obQuantityLimit = {};
        this.obPict = null;
        this.obSecondPict = null;
        this.obPictSlider = null;
        this.obPictSliderIndicator = null;
        this.obPrice = null;
        this.obTree = null;
        this.obBuyBtn = null;
        this.obBasketActions = null;
        this.obNotAvail = null;
        this.obSubscribe = null;
        this.obDscPerc = null;
        this.obSecondDscPerc = null;
        this.obSkuProps = null;
        this.obMeasure = null;
        this.obCompare = null;
        this.obWishlistBtn = null;
        this.obWishlistBigBtn = null;

        this.obPopupWin = null;
        this.basketUrl = '';
        this.basketParams = {};
        //this.isTouchDevice = BX.hasClass(document.documentElement, 'bx-touch');
        this.hoverTimer = null;
        this.hoverStateChangeForbidden = false;
        this.mouseX = null;
        this.mouseY = null;

        this.obPictureLink = null;
        this.obTitleLink = null;

        this.useEnhancedEcommerce = false;
        this.dataLayerName = 'dataLayer';
        this.brandProperty = false;
        this.labelBlockNode = null;
        this.saleCounterBlockNode = null;
        this.timerData = false;
        this.imageBlockNode = null;
        this.checkedColor = false;

        this.errorCode = 0;
        this.available = params.AVAILABLE;
        this.messages = (params.hasOwnProperty('MESSAGES')) ? params.MESSAGES : {};
        this.basketMessages = (params.hasOwnProperty('BASKET_MESSAGES')) ? params.BASKET_MESSAGES : {};
        if (typeof params === 'object') {
            if (params.PRODUCT_TYPE) {
                this.productType = parseInt(params.PRODUCT_TYPE, 10);
            }

            this.fUserId = params.FUSER_ID;
            this.currencyList = params.CURRENCIES;
            this.showQuantity = params.SHOW_QUANTITY;
            this.haveDiscount = params.HAVE_DISCOUNT;
            this.valueDiscount = params.DISCOUNT_VALUE;
            this.showAbsent = params.SHOW_ABSENT;
            this.secondPict = params.SECOND_PICT;
            this.showOldPrice = params.SHOW_OLD_PRICE;
            this.showMaxQuantity = params.SHOW_MAX_QUANTITY;
            this.relativeQuantityFactor = parseInt(params.RELATIVE_QUANTITY_FACTOR);
            this.showPercent = params.SHOW_DISCOUNT_PERCENT;
            this.showSkuProps = params.SHOW_SKU_PROPS;
            this.showSubscription = params.USE_SUBSCRIBE;

            if (params.ADD_TO_BASKET_ACTION) {
                this.basketAction = params.ADD_TO_BASKET_ACTION;
            }

            this.showClosePopup = params.SHOW_CLOSE_POPUP;
            this.useCompare = params.DISPLAY_COMPARE;
            this.fullDisplayMode = params.PRODUCT_DISPLAY_MODE === 'Y';
            this.bigData = params.BIG_DATA;
            this.viewMode = params.VIEW_MODE || '';
            this.templateTheme = params.TEMPLATE_THEME || '';
            this.useEnhancedEcommerce = params.USE_ENHANCED_ECOMMERCE === 'Y';
            this.dataLayerName = params.DATA_LAYER_NAME;
            this.brandProperty = params.BRAND_PROPERTY;

            this.visual = params.VISUAL;

            this.cartAjaxPath = params.CART_AJAX_PATH;
            this.paramPath = params.PARAM_PATH;
            this.siteId = params.SITE_ID;
            this.baseCurrency = params.BASE_CURRENCY;
            this.productArticle = params.ARTICLE;
            this.displayProperties = params.DISPLAY_PROPERTIES;
            this.productProperties = params.PROPERTIES;
            this.promotionLabel = params.PROMOTION_LABEL;

            if (params.hasOwnProperty('AJAX_PAGE_URL')) {
                this.ajaxPageUrl = params['AJAX_PAGE_URL'];
            }

            switch (this.productType) {
                case 0: // no catalog
                case 1: // product
                case 2: // set
                    if (params.PRODUCT && typeof params.PRODUCT === 'object') {
                        this.currentPriceMode = params.PRODUCT.ITEM_PRICE_MODE;
                        this.currentPrices = params.PRODUCT.ITEM_PRICES;
                        this.currentPriceSelected = params.PRODUCT.ITEM_PRICE_SELECTED;
                        this.currentQuantityRanges = params.PRODUCT.ITEM_QUANTITY_RANGES;
                        this.currentQuantityRangeSelected = params.PRODUCT.ITEM_QUANTITY_RANGE_SELECTED;

                        if (this.showQuantity) {
                            this.product.checkQuantity = params.PRODUCT.CHECK_QUANTITY;
                            this.product.isDblQuantity = params.PRODUCT.QUANTITY_FLOAT;

                            if (this.product.checkQuantity) {
                                this.product.maxQuantity = (this.product.isDblQuantity ? parseFloat(params.PRODUCT.MAX_QUANTITY) : parseInt(params.PRODUCT.MAX_QUANTITY, 10));
                            }

                            this.product.stepQuantity = (this.product.isDblQuantity ? parseFloat(params.PRODUCT.STEP_QUANTITY) : parseInt(params.PRODUCT.STEP_QUANTITY, 10));

                            this.checkQuantity = this.product.checkQuantity;
                            this.isDblQuantity = this.product.isDblQuantity;
                            this.stepQuantity = this.product.stepQuantity;
                            this.maxQuantity = this.product.maxQuantity;
                            this.minQuantity = this.currentPriceMode === 'Q'
                                ? parseFloat(this.currentPrices[this.currentPriceSelected].MIN_QUANTITY)
                                : this.stepQuantity;

                            if (this.isDblQuantity) {
                                this.stepQuantity = Math.round(this.stepQuantity * this.precisionFactor) / this.precisionFactor;
                            }
                        }

                        this.product.canBuy = params.PRODUCT.CAN_BUY;

                        if (params.PRODUCT.MORE_PHOTO_COUNT) {
                            this.product.morePhotoCount = params.PRODUCT.MORE_PHOTO_COUNT;
                            this.product.morePhoto = params.PRODUCT.MORE_PHOTO;
                        }

                        if (params.PRODUCT.RCM_ID) {
                            this.product.rcmId = params.PRODUCT.RCM_ID;
                        }

                        this.canBuy = this.product.canBuy;
                        this.product.name = params.PRODUCT.NAME;
                        this.product.pict = params.PRODUCT.PICT;
                        this.product.id = params.PRODUCT.ID;
                        this.product.DETAIL_PAGE_URL = params.PRODUCT.DETAIL_PAGE_URL;
                        this.product.noImageSrc = params.PRODUCT.NO_IMAGE_SRC;

                        if (params.PRODUCT.ADD_URL) {
                            this.product.addUrl = params.PRODUCT.ADD_URL;
                        }

                        if (params.PRODUCT.BUY_URL) {
                            this.product.buyUrl = params.PRODUCT.BUY_URL;
                        }

                        if (params.BASKET && typeof params.BASKET === 'object') {
                            this.basketData.useProps = params.BASKET.ADD_PROPS;
                            this.basketData.emptyProps = params.BASKET.EMPTY_PROPS;
                        }
                    } else {
                        this.errorCode = -1;
                    }

                    break;
                case 3: // sku
                    if (params.PRODUCT && typeof params.PRODUCT === 'object') {
                        this.product.name = params.PRODUCT.NAME;
                        this.product.id = params.PRODUCT.ID;
                        this.product.DETAIL_PAGE_URL = params.PRODUCT.DETAIL_PAGE_URL;
                        this.product.morePhotoCount = params.PRODUCT.MORE_PHOTO_COUNT;
                        this.product.morePhoto = params.PRODUCT.MORE_PHOTO;
                        this.product.properties = params.PRODUCT.PROPERTIES;
                        this.product.noImageSrc = params.PRODUCT.NO_IMAGE_SRC;

                        if (params.PRODUCT.RCM_ID) {
                            this.product.rcmId = params.PRODUCT.RCM_ID;
                        }
                    }

                    if (params.OFFERS && Array.isArray(params.OFFERS)) {
                        this.offers = params.OFFERS;
                        this.offerNum = 0;

                        if (params.OFFER_SELECTED) {
                            this.offerNum = parseInt(params.OFFER_SELECTED, 10);
                        }

                        if (isNaN(this.offerNum)) {
                            this.offerNum = 0;
                        }

                        if (params.TREE_PROPS) {
                            this.treeProps = params.TREE_PROPS;
                        }

                        if (params.DEFAULT_PICTURE) {
                            this.defaultPict = params.DEFAULT_PICTURE;
                        }
                    }
                    break;
                default:
                    this.errorCode = -1;
            }
            if (params.BASKET && typeof params.BASKET === 'object') {
                if (params.BASKET.QUANTITY) {
                    this.basketData.quantity = params.BASKET.QUANTITY;
                }

                if (params.BASKET.PROPS) {
                    this.basketData.props = params.BASKET.PROPS;
                }

                if (params.BASKET.BASKET_URL) {
                    this.basketData.basketUrl = params.BASKET.BASKET_URL;
                }

                if (3 === this.productType) {
                    if (params.BASKET.SKU_PROPS) {
                        this.basketData.sku_props = params.BASKET.SKU_PROPS;
                    }
                }

                if (params.BASKET.ADD_URL_TEMPLATE) {
                    this.basketData.add_url = params.BASKET.ADD_URL_TEMPLATE;
                }

                if (params.BASKET.BUY_URL_TEMPLATE) {
                    this.basketData.buy_url = params.BASKET.BUY_URL_TEMPLATE;
                }

                if (this.basketData.add_url === '' && this.basketData.buy_url === '') {
                    this.errorCode = -1024;
                }
            }

            if (this.useCompare) {
                if (params.COMPARE && typeof params.COMPARE === 'object') {
                    if (params.COMPARE.COMPARE_PATH) {
                        this.compareData.comparePath = params.COMPARE.COMPARE_PATH;
                    }

                    if (params.COMPARE.COMPARE_URL_TEMPLATE) {
                        this.compareData.compareUrl = params.COMPARE.COMPARE_URL_TEMPLATE;
                    } else {
                        this.useCompare = false;
                    }

                    if (params.COMPARE.COMPARE_DELETE_URL_TEMPLATE) {
                        this.compareData.compareDeleteUrl = params.COMPARE.COMPARE_DELETE_URL_TEMPLATE;
                    } else {
                        this.useCompare = false;
                    }
                } else {
                    this.useCompare = false;
                }
            }
        }

        if (this.errorCode === 0) {
            this.init();
        } else console.error(this.errorCode)
    }

    set messages(data) {
        this._messages = data;
    }

    message(code) {
        return this._messages.hasOwnProperty(code) ? this._messages[code] : '';
    }

    init() {
        let self = this;
        let i = 0,
            treeItems = null;

        this.obProduct = document.querySelector(`[data-id=${this.visual.ID}]`);
        if (!this.obProduct) {
            this.errorCode = -1;
        }

        if (this.obProduct && this.obProduct.dataset.hasOwnProperty('params')) {
            this.productParams = JSON.parse(atob(this.obProduct.dataset.params));
        }

        if (this.obProduct && this.obProduct.dataset.hasOwnProperty('top')) {
            this.productTop = JSON.parse(atob(this.obProduct.dataset.top));
        }

        this.obPict = document.getElementById(this.visual.PICT_ID);
        if (!this.obPict) {
            this.errorCode = -2;
        }

        if (this.secondPict && this.visual.SECOND_PICT_ID) {
            this.obSecondPict = document.getElementById(this.visual.SECOND_PICT_ID);
        }

        this.obPrice = document.getElementById(this.visual.PRICE_ID);
        this.obPriceOld = document.getElementById(this.visual.PRICE_OLD_ID);
        this.obPriceTotal = document.getElementById(this.visual.PRICE_TOTAL_ID);
        if (!this.obPrice) {
            this.errorCode = -16;
        }

        if (this.showQuantity && this.visual.QUANTITY_ID) {
            this.obQuantity = document.getElementById(this.visual.QUANTITY_ID);
            this.blockNodes.quantity = this.obProduct.querySelector('[data-entity="quantity-block"]');
        }

        if (this.visual.QUANTITY_LIMIT && this.showMaxQuantity !== 'N') {
            this.obQuantityLimit.all = document.getElementById(this.visual.QUANTITY_LIMIT);
            if (this.obQuantityLimit.all) {
                this.obQuantityLimit.value = this.obQuantityLimit.all.querySelector('[data-entity="quantity-limit-value"]');
                if (!this.obQuantityLimit.value) {
                    this.obQuantityLimit.all = null;
                }
            }
        }

        if (this.productType === 3 && this.fullDisplayMode) {
            if (this.visual.TREE_ID) {
                this.obTree = document.getElementById(this.visual.TREE_ID);
                if (!this.obTree) {
                    this.errorCode = -256;
                }
            }

            if (this.visual.QUANTITY_MEASURE) {
                this.obMeasure = document.getElementById(this.visual.QUANTITY_MEASURE);
            }
        }

        this.obBasketActions = document.getElementById(this.visual.BASKET_ACTIONS_ID);

        if (this.obBasketActions) {
            if (this.visual.BUY_ID) {
                this.obBuyBtn = document.getElementById(this.visual.BUY_ID);
            }
        }

        if (this.visual.WISHLIST_ID) {
            this.obWishlistBtn = document.getElementById(this.visual.WISHLIST_ID);
        }

        if (this.visual.WISHLIST_BTN_ID) {
            this.obWishlistBigBtn = document.getElementById(this.visual.WISHLIST_BTN_ID);
        }

        this.obNotAvail = document.getElementById(this.visual.NOT_AVAILABLE_MESS);

        if (this.showSubscription) {
            this.obSubscribe = document.getElementById(this.visual.SUBSCRIBE_ID);
        }

        if (this.showPercent) {
            if (this.visual.DSC_PERC) {
                this.obDscPerc = document.getElementById(this.visual.DSC_PERC);
            }
            if (this.secondPict && this.visual.SECOND_DSC_PERC) {
                this.obSecondDscPerc = document.getElementById(this.visual.SECOND_DSC_PERC);
            }
        }

        if (this.showSkuProps) {
            if (this.visual.DISPLAY_PROP_DIV) {
                this.obSkuProps = document.getElementById(this.visual.DISPLAY_PROP_DIV);
            }
        }


        if (this.visual.PICTURE_LINK)
            this.obPictureLink = document.getElementById(this.visual.PICTURE_LINK);

        if (this.visual.TITLE_LINK)
            this.obTitleLink = document.getElementById(this.visual.TITLE_LINK);

        //Init timer
        if (this.visual.hasOwnProperty('SALE_COUNTER'))
            this.saleCounterBlockNode = document.getElementById(this.visual.SALE_COUNTER);

        if (this.productType === 3) {
            if (this.visual.hasOwnProperty('IMAGE_BLOCK_ID')) this.imageBlockNode = document.getElementById(this.visual.IMAGE_BLOCK_ID);
            if (this.visual.hasOwnProperty('LABEL_BLOCK_ID')) this.labelBlockNode = document.getElementById(this.visual.LABEL_BLOCK_ID);
        }

        if (this.errorCode === 0) {
            // product slider events
            if (this.bigData) {
                let links = this.obProduct.querySelectorAll('a');
                if (links.length > 0) {
                    for (i in links) {
                        if (links.hasOwnProperty(i)) {
                            if (links[i].getAttribute('href') == this.product.DETAIL_PAGE_URL) {
                                links[i].addEventListener('click', () => {
                                    self.rememberProductRecommendation();
                                });
                            }
                        }
                    }
                }
            }

            switch (this.productType) {
                case 0: // no catalog
                case 1: // product
                case 2: // set
                    eventEmitter.emit('OnSkuPropertyChange', [this.product.id, this.obBuyBtn]);
                    break;
                case 3: // sku
                    if (this.offers.length > 0) {
                        this.obTree?.addEventListener('click', (e) => {
                            self.onClickTreeBlock(e);
                        });

                        this.setCurrent();
                        this.imagesSlider();
                    }

                    break;
            }

            CatalogCurrency.setCurrencies(this.currencyList);
        }

        //Hover colors
        $('.product-colors__item').on('mouseover', function hoverColor() {
            $(this).find('.product-colors__info').addClass('active');
        });

        $('.product-colors__item, .product-colors__info').on('mouseleave', function () {
            $('.product-colors__info').removeClass('active');
        });

        this.setLocalStorageWishlist();

        this.obWishlistBtn?.addEventListener('click', () => {
            this.add2Wishlist();
        })

        this.obWishlistBigBtn?.addEventListener('click', () => {
            this.add2Wishlist();
        })

        this.obBuyBtn?.addEventListener('click', () => {
            this.add2Basket();
        })

        CatalogCurrency.setCurrencies(this.currencyList);
    }

    setTimer(timer, type) {
        this.timerData = timer;

        if (this.saleCounterBlockNode && this.timerData) {
            let saleLabel = this.labelBlockNode.querySelector('.product-sale');
            if (saleLabel instanceof HTMLElement)
                saleLabel.parentNode.removeChild(saleLabel);

            this.saleCounterBlockNode.setAttribute('data-time', this.timerData);
            this.saleCounterBlockNode.setAttribute('data-type', type);
            this.saleCounterBlockNode.style.display = 'block';
            this.displayTimer = true;
        }
    }

    onClickTreeBlock(e) {
        let node = e.target;
        if (e.target.hasAttribute('data-items') && !e.target.classList.contains('processed')) {
            let data = JSON.parse(window.atob(e.target.getAttribute('data-items')));
            if (data instanceof Object) {
                let html = this.getTreeBlockHtml(data);
                e.target.parentNode.insertAdjacentHTML(
                    'beforeend',
                    '<div class="product-colors__list scroll-text open" data-parent="' + e.target.getAttribute('data-id') + '"><div class="product-colors__list-inner">' + html + '</div></div>'
                );

                // Получаем значение атрибута data-id из события
                const dataId = e.target.getAttribute('data-id');

                // Находим все элементы с атрибутом data-parent, равным значению data-id
                const elements = document.querySelectorAll(`[data-parent="${dataId}"]`);

                // Определяем массив классов, которые нужно добавить
                const classesToAdd = [
                    'scrollbar-thumb-custom',
                    'overscroll-none',
                    '[&::-webkit-scrollbar]:w-2',
                    'scrollbar-track-custom-light',
                    'scrollbar-thumb-[#a6aeb2]',
                    'hover:scrollbar-thumb-[#a6aeb2]',
                    'scrollbar',
                    'overflow-y-scroll'
                ];

                // Добавляем классы ко всем найденным элементам
                elements.forEach(element => {
                    element.classList.add(...classesToAdd);
                });

                e.target.classList.add('processed');
            }

        } else if (node.hasAttribute('data-treevalue')) {
            this.selectOfferProp(node);
        }

    }

    getTreeBlockHtml(data) {
        let result = '';

        for (let i in data) {
            if (!data.hasOwnProperty(i)) continue;

            let checked = '';

            if (!this.checkedColor && data[i]['CHECKED']) {
                this.checkedColor = Number(data[i]['ID']);
                checked = ' checked ';
            }

            result += '<div class="product-colors__item">' +
                '<label>' +
                '<input type="radio" ' +
                'value="' + data[i]['XML_ID'] + '"' +
                ' name="' + data[i]['INPUT_NAME'] + '"' +
                ' data-treevalue="' + data[i]['PROPERTY_ID'] + '_' + data[i]['ID'] + '"' +
                ' data-onevalue="' + data[i]['ID'] + '"' +
                ' data-property="' + data[i]['PROPERTY_CODE'] + '"' + checked + '>' +
                '<span><img class="lazyload" data-src="' + data[i]['PICTURE'] + '" width="26" height="26" alt="' + data[i]['NAME'] + '"></span>' +
                '</label>' +
                '<div class="product-colors__info">' + data[i]['NAME'] + '</div>' +
                '</div>';
        }

        return result;
    }

    sendSelectedOffer() {
        let data = {
            sessid: application.getSessionId(),
            product: this.product.id,
            offerSelected: this.offers[this.offerNum]['ID']
        };

        let formData = new FormData();

        for (let i in data) {
            if (data.hasOwnProperty(i))
                formData.append(i, data[i]);
        }

        return fetch(this.ajaxPageUrl, {method: 'POST', body: formData})
            .then(response => {
                return response.json()
            })
            .then(data => {
                return data
            });
    }

    selectOfferProp(target) {
        let i = 0,
            value = '',
            strTreeValue = '',
            arTreeItem = [],
            rowItems = null;

        if (target && target.hasAttribute('data-treevalue')) {
            if (target.classList.contains('selected'))
                return;

            strTreeValue = target.getAttribute('data-treevalue');
            arTreeItem = strTreeValue.split('_');

            this.checkedColor = Number(arTreeItem[1]);
            if (this.searchOfferPropIndex(arTreeItem[0], arTreeItem[1])) {
                rowItems = this.obTree.querySelectorAll('input');
                if (rowItems && 0 < rowItems.length) {
                    for (i = 0; i < rowItems.length; i++) {
                        value = rowItems[i].getAttribute('data-onevalue');
                        if (value === arTreeItem[1]) {
                            rowItems[i].checked = true;
                            rowItems[i].classList.add('selected');
                        } else {
                            rowItems[i].checked = false;
                            rowItems[i].classList.remove('selected');
                        }
                    }
                }
            }
        }
    }

    searchOfferPropIndex(strPropID, strPropValue) {
        let strName = '',
            arShowValues = false,
            i, j,
            arCanBuyValues = [],
            allValues = [],
            index = -1,
            arFilter = {},
            tmpFilter = {};

        for (i = 0; i < this.treeProps.length; i++) {
            if (this.treeProps[i].ID === Number(strPropID)) {
                index = i;
                break;
            }
        }

        if (-1 < index) {
            for (i = 0; i < index; i++) {
                strName = 'PROP_' + this.treeProps[i].ID;
                arFilter[strName] = this.selectedValues[strName];
            }
            strName = 'PROP_' + this.treeProps[index].ID;
            arShowValues = this.getRowValues(arFilter, strName);

            if (!arShowValues) {
                return false;
            }
            if (arShowValues.indexOf(Number(strPropValue)) === -1) {
                return false;
            }

            arFilter[strName] = strPropValue;
            for (i = index + 1; i < this.treeProps.length; i++) {
                strName = 'PROP_' + this.treeProps[i].ID;
                arShowValues = this.getRowValues(arFilter, strName);
                if (!arShowValues) {
                    return false;
                }
                allValues = [];
                if (this.showAbsent) {
                    arCanBuyValues = [];
                    tmpFilter = {};
                    tmpFilter = Object.assign(tmpFilter, arFilter);
                    for (j = 0; j < arShowValues.length; j++) {
                        tmpFilter[strName] = arShowValues[j];
                        allValues[allValues.length] = arShowValues[j];
                        if (this.getCanBuy(tmpFilter))
                            arCanBuyValues[arCanBuyValues.length] = arShowValues[j];
                    }
                } else {
                    arCanBuyValues = arShowValues;
                }

                if (this.selectedValues[strName] && arCanBuyValues.indexOf(this.selectedValues[strName]) !== -1) {
                    arFilter[strName] = this.selectedValues[strName];
                } else {
                    if (this.showAbsent)
                        arFilter[strName] = (arCanBuyValues.length > 0 ? arCanBuyValues[0] : allValues[0]);
                    else
                        arFilter[strName] = arCanBuyValues[0];
                }
                //this.updateRow(i, arFilter[strName], arShowValues, arCanBuyValues);
            }
            this.selectedValues = arFilter;

            this.changeInfo();
            this.sendSelectedOffer();
        }
        return true;
    }

    updateRow(intNumber, activeID, showID, canBuyID) {
        let i = 0,
            value = '',
            isCurrent = false,
            rowItems = null;

        let lineContainer = this.obTree.querySelectorAll('[data-entity="sku-line-block"]');

        if (intNumber > -1 && intNumber < lineContainer.length) {
            rowItems = lineContainer[intNumber].querySelectorAll('input');
            if (rowItems && 0 < rowItems.length) {
                for (i = 0; i < rowItems.length; i++) {
                    value = rowItems[i].getAttribute('data-onevalue');
                    isCurrent = value === activeID;

                    if (isCurrent) {
                        rowItems[i].checked = true;
                        rowItems[i].classList.add('selected');
                    } else {
                        rowItems[i].checked = false;
                        rowItems[i].classList.remove('selected');
                    }

                    if (canBuyID.indexOf(value) !== -1) {
                        rowItems[i].classList.remove('notallowed');
                    } else {
                        rowItems[i].classList.add('notallowed');
                    }

                    rowItems[i].style.display = (showID.indexOf(value) !== -1) ? '' : 'none';

                    if (isCurrent) {
                        lineContainer[intNumber].style.display = (value == 0 && canBuyID.length == 1) ? 'none' : '';
                    }
                }
            }
        }
    }

    getRowValues(arFilter, index) {
        let i = 0,
            j,
            arValues = [],
            boolSearch = false,
            boolOneSearch = true;

        if (0 === arFilter.length) {
            for (i = 0; i < this.offers.length; i++) {
                if (arValues.indexOf(this.offers[i].TREE[index]) === -1) {
                    arValues[arValues.length] = this.offers[i].TREE[index];
                }
            }
            boolSearch = true;
        } else {
            for (i = 0; i < this.offers.length; i++) {
                boolOneSearch = true;
                for (j in arFilter) {
                    if (arFilter[j] !== this.offers[i].TREE[j]) {
                        boolOneSearch = false;
                        break;
                    }
                }
                if (boolOneSearch) {
                    if (arValues.indexOf(this.offers[i].TREE[index]) === -1) {
                        arValues[arValues.length] = this.offers[i].TREE[index];
                    }
                    boolSearch = true;
                }
            }
        }

        return (boolSearch ? arValues : false);
    }

    getCanBuy(arFilter) {
        let i, j,
            boolSearch = false,
            boolOneSearch = true;

        for (i = 0; i < this.offers.length; i++) {
            boolOneSearch = true;
            for (j in arFilter) {
                if (arFilter[j] !== this.offers[i].TREE[j]) {
                    boolOneSearch = false;
                    break;
                }
            }
            if (boolOneSearch) {
                if (this.offers[i].CAN_BUY) {
                    boolSearch = true;
                    break;
                }
            }
        }

        return boolSearch;
    }

    imagesSlider() {
        let images = this.offers[this.offerNum].IMAGES,
            currentBlock = this.offers[this.offerNum].ID,
            currentBlockID = this.visual.ID;

        // Находим блок div с атрибутом data-currentid, равным значению переменной currentBlock
        let targetDiv = document.querySelector(`div[data-id="${currentBlockID}"]`);

        //console.info('images',images)

        /*if (targetDiv) {
            // Находим внутри этого блока блок с классом product__image
            let swiperWrapper = targetDiv.querySelector('swiper-container');

            if (swiperWrapper) {
                // После добавления всех слайдов, переинициализируем Swiper
                var mySwiper = new Swiper(swiperWrapper, {
                    // Настройки карусели
                    loop: true, // Зацикливание карусели
                    slidesPerView: 1, // Одновременно отображается только один слайд
                    /!*watchSlidesVisibility: true,*!/
                    lazy: true,
                    // Добавление навигации (кнопок для листания)
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                    // Добавление пагинации (кружочков)
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true, // Разрешить кликать по кружочкам для переключения слайдов
                    },
                })
                var targetBlock = targetDiv.querySelector('.swiper-container-'+currentBlock);
                if (targetBlock) {
                    targetBlock.style.display = 'block';
                }
            } else {
                console.error('Не удалось найти блок с классом product__image внутри блока с атрибутом data-currentid');
            }
        } else {
            console.error('Не удалось найти блок с атрибутом data-currentid равным значению переменной currentBlock');
        }*/
    }

    setCurrent() {
        let i,
            j = 0,
            arCanBuyValues = [],
            strName = '',
            arShowValues = false,
            arFilter = {},
            tmpFilter = {},
            current = this.offers[this.offerNum].TREE;
        for (i = 0; i < this.treeProps.length; i++) {
            strName = 'PROP_' + this.treeProps[i].ID;
            arShowValues = this.getRowValues(arFilter, strName);
            if (!arShowValues) {
                break;
            }

            if (arShowValues.indexOf(current[strName]) !== -1) {
                arFilter[strName] = current[strName];
            } else {
                arFilter[strName] = arShowValues[0];
                this.offerNum = 0;
            }
            if (this.showAbsent) {
                arCanBuyValues = [];
                tmpFilter = {};
                tmpFilter = Object.assign(tmpFilter, arFilter);
                for (j = 0; j < arShowValues.length; j++) {
                    tmpFilter[strName] = arShowValues[j];
                    if (this.getCanBuy(tmpFilter)) {
                        arCanBuyValues[arCanBuyValues.length] = arShowValues[j];
                    }
                }
            } else {
                arCanBuyValues = arShowValues;
            }
            //this.updateRow(i, arFilter[strName], arShowValues, arCanBuyValues);
        }
        this.selectedValues = arFilter;
        this.changeInfo();
    }

    /**
     * Select actual offer
     */
    changeInfo() {
        let i, j,
            index = -1,
            boolOneSearch = true,
            quantityChanged;

        for (i = 0; i < this.offers.length; i++) {
            boolOneSearch = true;
            for (j in this.selectedValues) {
                if (this.selectedValues[j] != this.offers[i].TREE[j]) {
                    boolOneSearch = false;
                    break;
                }
            }
            if (boolOneSearch) {
                index = i;
                break;
            }
        }

        if (index > -1) {
            // show pict and pict_second containers
            if (this.obPict) {
                let selected_offer = this.offers[index];
                
                if (selected_offer.hasOwnProperty('PICTURE') && selected_offer.PICTURE && selected_offer.PICTURE.hasOwnProperty('SRC')) {
                    this.obPict.src = this.offers[index].PICTURE.SRC;
                } else if (this.defaultPict) {
                    this.obPict.src = this.defaultPict.SRC;
                } else {
                    this.obPict.src = this.product.noImageSrc;
                }
            }

            if (this.showSkuProps && this.obSkuProps) {
                if (this.offers[index].DISPLAY_PROPERTIES.length) {
                    //BX.adjust(this.obSkuProps, {style: {display: ''}, html: this.offers[index].DISPLAY_PROPERTIES});
                    this.obSkuProps.innerHTML = this.offers[index].DISPLAY_PROPERTIES;
                    this.obSkuProps.style.display = '';
                } else {
                    this.obSkuProps.style.display = 'none';
                    this.obSkuProps.innerHTML = '';
                }
            }


            this.offerNum = index;
            //
            let newOffer = this.offers[index],
                oldOffer = this.offers[this.offerNum];
            this.canBuy = newOffer.CAN_BUY;

            this.currentPriceMode = newOffer.ITEM_PRICE_MODE;
            this.currentPrices = newOffer.ITEM_PRICES;
            this.currentPriceSelected = newOffer.ITEM_PRICE_SELECTED;
            this.currentQuantityRanges = newOffer.ITEM_QUANTITY_RANGES;
            this.currentQuantityRangeSelected = newOffer.ITEM_QUANTITY_RANGE_SELECTED;

            this.isDblQuantity = newOffer.QUANTITY_FLOAT;
            this.checkQuantity = newOffer.CHECK_QUANTITY;
            //
            this.setPrice();
            this.obBuyBtn?.setAttribute('data-currentid', this.offers[this.offerNum].ID);
            eventEmitter.emit('OnSkuPropertyChange', [this.offers[this.offerNum].ID, this.obBuyBtn]);
        }
    }

    setPrice() {
        let obData, price;
        let priceClassName = 'product__price-current';
        let labelHTML = '';

        price = this.currentPrices[this.currentPriceSelected];

        if (this.obPrice && this.available) {
            if (price) {
                if (parseFloat(price.DISCOUNT) > 0) priceClassName = 'product__price-new';

                this.obPrice.className = priceClassName;

                this.obPrice.innerHTML = CatalogCurrency.currencyFormat(price.RATIO_PRICE, price.CURRENCY, true);
            } else {
                this.obPrice.innerHTML = '';
            }

            if (this.showOldPrice && this.obPriceOld) {
                if (price && price.RATIO_PRICE !== price.RATIO_BASE_PRICE) {
                    this.obPriceOld.style.display = '';
                    this.obPriceOld.innerHTML = CatalogCurrency.currencyFormat(price.RATIO_BASE_PRICE, price.CURRENCY, true);
                } else {
                    this.obPriceOld.style.display = 'none';
                    this.obPriceOld.innerHTML = '';
                }
            }

            if (this.obPriceTotal) {
                if (price && this.obQuantity && this.obQuantity.value != this.stepQuantity) {
                    this.obPriceTotal.innerHTML = this.message('PRICE_TOTAL_PREFIX') + ' <strong>'
                        + CatalogCurrency.currencyFormat(price.PRICE * this.obQuantity.value, price.CURRENCY, true)
                        + '</strong>';
                    this.obPriceTotal.style.display = '';
                } else {
                    this.obPriceTotal.style.display = 'none';
                    this.obPriceTotal.innerHTML = '';
                }
            }

            if (this.labelBlockNode instanceof HTMLElement) {
                if (price && parseInt(price.DISCOUNT) > 0) {
                    labelHTML = '<div class="product-label product-label--sale">Sale</div>';
                    if (!this.displayTimer) {
                        labelHTML += '<div class="product-sale">' + '<span>- ' + price.PERCENT + '%</span>' + '</div>';
                    }

                } else if (this.haveDiscount) {
                    labelHTML = '<div class="product-label product-label--sale">Sale</div>';
                    if (!this.displayTimer) {
                        labelHTML += '<div class="product-sale">' + '<span>- ' + this.valueDiscount + '%</span>' + '</div>';
                    }

                } else if (this.productParams.hasOwnProperty('new') && this.productParams.new) {
                    labelHTML = '<div class="product-label product-label--new">New</div>';
                } else if (this.productTop.hasOwnProperty('top') && this.productTop.top) {
                    labelHTML = '<div class="product-label product-label--top">TOP</div>';
                }

                if (this.promotionLabel.hasOwnProperty('PICTURE') && this.promotionLabel['PICTURE'].length > 0) {
                    labelHTML += `<div class="freeShipping product-freeShipping" data-container="promotion-label">
                    <img src="${this.promotionLabel['PICTURE']}" 
                         alt="freeShipping"
                         height="70"
                         width="70">
                	</div>`;
                }

                this.labelBlockNode.innerHTML = labelHTML;
            }
        } else {
            if (this.labelBlockNode instanceof HTMLElement) {
                if (this.promotionLabel.hasOwnProperty('PICTURE') && this.promotionLabel['PICTURE'].length > 0) {
                    this.labelBlockNode.innerHTML = `<div class="freeShipping product-freeShipping" data-container="promotion-label">
                    <img src="${this.promotionLabel['PICTURE']}"
                         alt="freeShipping"
                         height="70"
                         width="70">
                	</div>`;
                }
            }
        }
    }

    initBasketUrl() {
        console.info('this.offers', this.offers)
        this.basketUrl = (this.basketMode === 'ADD' ? this.basketData.add_url : this.basketData.buy_url);
        switch (this.productType) {
            case 1: // product
            case 2: // set
                this.basketUrl = this.basketUrl.replace('#ID#', this.product.id.toString());
                break;
            case 3: // sku
                this.basketUrl = this.basketUrl.replace('#ID#', this.offers[this.offerNum].ID);
                break;
        }

        this.basketParams = {
            'ajax_basket': 'Y'
        };
        if (this.showQuantity) {
            this.basketParams[this.basketData.quantity] = this.obQuantity.value;
        }
        if (this.basketData.sku_props) {
            this.basketParams[this.basketData.sku_props_var] = this.basketData.sku_props;
        }
    }

    fillBasketProps() {
        if (!this.visual.BASKET_PROP_DIV) {
            return;
        }
        var
            i = 0,
            propCollection = null,
            foundValues = false,
            obBasketProps = null;

        if (this.basketData.useProps && !this.basketData.emptyProps) {
            if (this.obPopupWin && this.obPopupWin.contentContainer) {
                obBasketProps = this.obPopupWin.contentContainer;
            }
        } else {
            obBasketProps = document.getElementById(this.visual.BASKET_PROP_DIV);
        }
        if (obBasketProps) {
            propCollection = obBasketProps.getElementsByTagName('select');
            if (propCollection && propCollection.length) {
                for (i = 0; i < propCollection.length; i++) {
                    if (!propCollection[i].disabled) {
                        switch (propCollection[i].type.toLowerCase()) {
                            case 'select-one':
                                this.basketParams[propCollection[i].name] = propCollection[i].value;
                                foundValues = true;
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
            propCollection = obBasketProps.getElementsByTagName('input');
            if (propCollection && propCollection.length) {
                for (i = 0; i < propCollection.length; i++) {
                    if (!propCollection[i].disabled) {
                        switch (propCollection[i].type.toLowerCase()) {
                            case 'hidden':
                                this.basketParams[propCollection[i].name] = propCollection[i].value;
                                foundValues = true;
                                break;
                            case 'radio':
                                if (propCollection[i].checked) {
                                    this.basketParams[propCollection[i].name] = propCollection[i].value;
                                    foundValues = true;
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
            }
        }
        if (!foundValues) {
            this.basketParams[this.basketData.props] = [];
            this.basketParams[this.basketData.props][0] = 0;
        }
    }

    add2Basket() {
        this.basketMode = 'ADD';
        this.basket();
    }

    updateWishlist(productId) {
        // Получаем элемент wishlistItem из localStorage
        let wishlistItem = localStorage.getItem('wishlistItem');

        // Если wishlistItem существует, парсим его, иначе создаем пустой массив
        wishlistItem = wishlistItem ? JSON.parse(wishlistItem) : [];

        // Проверяем, есть ли productId в массиве wishlistItem
        const index = wishlistItem.indexOf(productId);

        if (index !== -1) {
            // Если productId найден, удаляем его из массива
            wishlistItem.splice(index, 1);
        } else {
            // Если productId не найден, добавляем его в массив
            wishlistItem.push(productId);
        }

        // Сохраняем обновленный массив wishlistItem обратно в localStorage
        localStorage.setItem('wishlistItem', JSON.stringify(wishlistItem));
    }

    setLocalStorageWishlist() {
        // Находим элемент по ID
        var element = document.getElementById(this.visual.WISHLIST_ID);
        var elementBigBnt = document.getElementById(this.visual.WISHLIST_BTN_ID);

        // Проверяем, что элемент найден
        if (element) {
            // Получаем значение атрибута data-product-id
            var productId = element.getAttribute('data-product-id');

            // Получаем элемент wishlistItem из localStorage
            let wishlistItem = localStorage.getItem('wishlistItem');
            // Если wishlistItem пустой или не существует
            if (wishlistItem === null) {

            } else {
                // Проверяем, содержится ли productId в wishlistItem
                if (wishlistItem.includes(productId)) {
                    element.classList.add('active');
                    element.classList.add('active');
                    if (elementBigBnt) {
                        elementBigBnt.classList.add('popup-open');
                        elementBigBnt.classList.remove('removed');
                        elementBigBnt.innerHTML = '<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/sprite.svg#close"></use></svg>' + this.message('CT_DELETE');
                    }
                } else {
                    element.classList.remove('active');
                    if (elementBigBnt) {
                        elementBigBnt.classList.add('removed');
                        elementBigBnt.classList.remove('popup-open');
                        elementBigBnt.innerHTML = '<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/sprite.svg#return"></use></svg>' + this.message('CT_RESTORE');
                    }
                }
            }
        }
    }

    add2Wishlist() {
        // Находим элемент по ID
        var element = document.getElementById(this.visual.WISHLIST_ID);
        var elementBigBnt = document.getElementById(this.visual.WISHLIST_BTN_ID);

        // Проверяем, что элемент найден
        if (element) {
            var isAuthorize = element.getAttribute('data-authorize');

            if (isAuthorize == 'N') {
                authRegister.show();
            } else {
                // Получаем значение атрибута data-product-id
                var productId = element.getAttribute('data-product-id');
                const productPrice = element.getAttribute('data-price');
                const productAvailable = element.getAttribute('data-available');

                const config = {headers: {'X-Requested-With': 'XMLHttpRequest'}};

                let formData = new FormData();
                formData.append('sessid', application.getSessionId());
                formData.append('productId', productId);

                axios.post('/api/wishlist/wishlistAction/', formData, config).
                    then(response => {
                        if (response.data.action === 'add') {
                            // Esputnik Tracker
                            esputnikTracker.AddToWishlist(
                                productId,
                                productPrice,
                                productAvailable);

                            element.classList.add('active');
                            if (elementBigBnt) {
                                elementBigBnt.classList.add('popup-open');
                                elementBigBnt.classList.remove('removed');
                                elementBigBnt.innerHTML = '<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/sprite.svg#close"></use></svg>' +
                                    this.message('CT_DELETE');
                            }
                            this.updateWishlist(productId);
                        } else if (response.data.action === 'delete') {
                            element.classList.remove('active');
                            if (elementBigBnt) {
                                elementBigBnt.classList.add('removed');
                                elementBigBnt.classList.remove('popup-open');
                                elementBigBnt.innerHTML = '<svg><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/images/sprite.svg#return"></use></svg>' +
                                    this.message('CT_RESTORE');
                            }
                            this.updateWishlist(productId);
                        }

                        var wishlistBlock = document.querySelector(
                            '.header-links__item--wishlist');

                        // Проверить, что блок найден
                        if (wishlistBlock) {
                            var quantitySpan = wishlistBlock.querySelector(
                                'span.wishlist-quantity');
                            if (quantitySpan) {
                                // Установить содержимое span в значение response.data.total, если оно не пустое и не равно 0
                                if (response.data.total &&
                                    response.data.total !== 0) {
                                    quantitySpan.textContent = response.data.total;
                                    quantitySpan.style.display = 'flex';
                                } else {
                                    quantitySpan.textContent = '';
                                    quantitySpan.style.display = 'none';
                                }
                            }
                        }
                        const mobileMenuWishlist = document.querySelector('.tap-bar-item-wishlist-count');
                        if (mobileMenuWishlist) {
                            // Установить содержимое span в значение response.data.total, если оно не пустое и не равно 0
                            if (response.data.total && response.data.total !== 0) {
                                mobileMenuWishlist.textContent = response.data.total;
                                mobileMenuWishlist.style.display = 'flex';
                            } else {
                                mobileMenuWishlist.textContent = '';
                                mobileMenuWishlist.style.display = 'none';
                            }
                        }
                    }).
                    catch(e => {
                        console.log(e)
                    })
            }
        }
    }

    buyBasket() {
        this.basketMode = 'BUY';
        this.basket();
    }

    sendToBasket() {
        if (!this.canBuy) {
            return;
        }

        // check recommendation
        if (this.product && this.product.id && this.bigData) {
            this.rememberProductRecommendation();
        }

        this.initBasketUrl();
        this.fillBasketProps();
        let formData = new FormData();

        for(let i in this.basketParams) {
            if (this.basketParams.hasOwnProperty(i))
                formData.append(i, this.basketParams[i]);
        }

        fetch(this.basketUrl, {
            method: 'POST',
            body:formData
        })
        .then(response =>{
            return response.json();
        })
        .then(data => {
            if(data) this.basketResult(data);
        });

        /*BX.ajax({
            method: 'POST',
            dataType: 'json',
            url: this.basketUrl,
            data: this.basketParams,
            //onsuccess: BX.proxy(this.basketResult, this)
        });*/
    }

    basket() {
        let contentBasketProps = '';
        if (!this.canBuy) {
            return;
        }
        switch (this.productType) {
            case 1: // product
            case 2: // set
                if (this.basketData.useProps && !this.basketData.emptyProps) {
                    this.initPopupWindow();
                    this.obPopupWin.setTitleBar(this.message('TITLE_BASKET_PROPS'));
                    if (document.getElementById(this.visual.BASKET_PROP_DIV)) {
                        contentBasketProps = document.getElementById(this.visual.BASKET_PROP_DIV).innerHTML;
                    }
                    this.obPopupWin.setContent(contentBasketProps);
                    /* this.obPopupWin.setButtons([
                         new BasketButton({
                             text: BX.message('BTN_MESSAGE_SEND_PROPS'),
                             events: {
                                 click: BX.delegate(this.sendToBasket, this)
                             }
                         })
                     ]);*/
                    this.obPopupWin.show();
                } else {
                    this.sendToBasket();
                }
                break;
            case 3: // sku
                this.sendToBasket();
                break;
        }
    }

    basketResult(arResult) {
        let data = {id: this.offers[this.offerNum].ID};
        let formData = new FormData();

        for(let i in data) {
            if (data.hasOwnProperty(i))
                formData.append(i, data[i]);
        }
        fetch(this.paramPath, {
            method: 'POST',
            body: formData
        }).then(response => {
            return response.json();
        }).then(result => {
            if (result){
                let popupContent, popupButtons, productPict;

                if (this.obPopupWin) {
                    this.obPopupWin.close();
                }

                if (typeof arResult !== 'object') {
                    this.sendingRequest = false;
                    return;
                }

                eventEmitter.emit('MSAddClickHistory', { productId: this.offers[this.offerNum].ID })
                eventEmitter.emit('MSAddToCart', { productId: this.offers[this.offerNum].ID })

                if (arResult) {
                    //this.setRemarketingAnalytics(this.offers[this.offerNum]);
                   //this.setAnalyticsFbq('AddToCart');
                }

                let curProduct = (this.productType === 3) ? this.offers[this.offerNum] : this.product;
                this.basketMessages['ADD_CART_STATUS'] = arResult.MESSAGE ? arResult.MESSAGE : this.basketMessages('BASKET_UNKNOWN_ERROR');

                let AddToCartWindowParams = {
                    params: result,
                    fuserId: this.fUserId,
                    successful: arResult.STATUS === 'OK',
                    product: curProduct,
                    productFields: this.product,
                    // message: arResult.MESSAGE ? arResult.MESSAGE : this.message('BASKET_UNKNOWN_ERROR'),
                    messages: this.basketMessages,
                    defaultPicture: this.product.noImageSrc,
                    ajaxPath: this.cartAjaxPath,
                    siteId: this.siteId,
                    baseCurrency: this.baseCurrency,
                    productCode: curProduct.PROPERTIES && typeof (curProduct.PROPERTIES.ARTNUMBER) !== 'undefined' ? curProduct.PROPERTIES.ARTNUMBER : ''
                }

                new AddToCartWindow(AddToCartWindowParams);
                this.sendingRequest = false;
                eventEmitter.emit('OnBasketChange');
            }
        })
    }

    basketRedirect() {
        location.href = (this.basketData.basketUrl ? this.basketData.basketUrl : this.message('BASKET_URL'));
    }

    initPopupWindow() {
        if (this.obPopupWin)
            return;

        this.obPopupWin = BX.PopupWindowManager.create('CatalogSectionBasket_' + this.visual.ID, null, {
            autoHide: true,
            offsetLeft: 0,
            offsetTop: 0,
            overlay: true,
            closeByEsc: true,
            titleBar: true,
            closeIcon: true,
            contentColor: 'white',
            className: this.templateTheme ? 'bx-' + this.templateTheme : ''
        });
    }

    getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        return matches ? decodeURIComponent(matches[1]) : null;
    }

    rememberProductRecommendation() {
        // save to RCM_PRODUCT_LOG
        let cookieName = BX.cookie_prefix + '_RCM_PRODUCT_LOG',
            cookie = this.getCookie(cookieName),
            itemFound = false;

        let cItems = [],
            cItem;

        if (cookie) {
            cItems = cookie.split('.');
        }

        let i = cItems.length;

        while (i--) {
            cItem = cItems[i].split('-');

            if (cItem[0] == this.product.id) {
                // it's already in recommendations, update the date
                cItem = cItems[i].split('-');

                // update rcmId and date
                cItem[1] = this.product.rcmId;
                cItem[2] = BX.current_server_time;

                cItems[i] = cItem.join('-');
                itemFound = true;
            } else {
                if ((BX.current_server_time - cItem[2]) > 3600 * 24 * 30) {
                    cItems.splice(i, 1);
                }
            }
        }

        if (!itemFound) {
            // add recommendation
            cItems.push([this.product.id, this.product.rcmId, BX.current_server_time].join('-'));
        }

        // serialize
        let plNewCookie = cItems.join('.'),
            cookieDate = new Date(new Date().getTime() + 1000 * 3600 * 24 * 365 * 10).toUTCString();

        document.cookie = cookieName + "=" + plNewCookie + "; path=/; expires=" + cookieDate + "; domain=" + BX.cookie_domain;
    }
}

export default Product